
import Vue from 'vue';
import config from "@/config";

export default Vue.extend({
    name: 'lead',
    components: {},
    data() {
        return {
            loading:true,
            isPrimary:true,
            onReachFlag:true,
            title:'历任领导',
            isZoomed:false,
            params:{ server:'1',category_id:'',event_id:'',page:1,limit:10},
            events: [],
            details:{
                title:'',
                desc:'',
                picImg:'',
            },
          }
    },
    created() {
        this.postUserInfo(this.$route.query.classify as string);
    },
    mounted() {
        const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
        const scrollHeight = scrollingDiv.scrollTop;
        scrollingDiv.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        getFullImagePath(relativePath: string) {
            return `${config.domain}${relativePath}`;
        },
        openimg(event) {
            // 检查 event.image 是否存在且是一个数组
            if (event.image && Array.isArray(event.image) && event.image.length > 0) {
                // 如果满足条件，将第一个元素赋给 this.details.picImg
                this.details.picImg = event.image[0];
                this.details = event
             }
                // else {
            //     console.error('Image array is undefined or empty.');
            //     // 在这里根据需要添加错误处理逻辑
            // }
            // 切换 this.isZoomed 的值
            this.isZoomed = !this.isZoomed;
        },
        goBack(){
            this.$router.replace({path:'/home'});
        },
        async postUserInfo(classify) {
            try {
                this.params.category_id = classify
                const result = await this.$api.postList(this.params);
                // this.events = result.data.list
                this.events = [...this.events, ...result.data.list];
                this.total =  result.data.total
                this.loading = false
                this.isPrimary = false
            } catch (error) {
                console.log('error', error)
            }
        },
        handleScroll() {
            const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
            const scrollHeight = scrollingDiv.scrollTop;
            const pageHeight = this.params.page * 80
            // console.log(scrollHeight,pageHeight)
            if (scrollHeight > pageHeight){
                // 显示底部提示
                const limitlen = this.total / this.params.limit
                // 判断是否有数据
                if ( this.params.page >= limitlen){
                    this.onReachFlag = false
                    return;
                }else {
                    console.log("还有数据")
                    this.onReachFlag = true
                }
                this.params.page ++
                this.postUserInfo(this.$route.query.classify as string);
            }
        },
    },
    beforeDestroy() {
        // 移除滚动事件监听，以避免内存泄漏
        window.removeEventListener('scroll', this.handleScroll);
    }
});
